
 $('.js-slider').slick({
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: false,
    adaptiveHeight: true,
    prevArrow: "<button type='button' class='slick-prev slick-arrow '><span></span></button>",
    nextArrow: "<button type='button' class='slick-next slick-arrow '><span></span></button>",
  })



const orderData = {
  1: {
    title: '1 Bottle',
    subtitle: 'Great For Just You!',
    price: ' $49.99',
    billed: 'One Time',
    img: '/images/bottle-dark.png',
    href: 'https://ambrosia-nutraceuticals.myshopify.com/cart/7696563470389:1',
    off: '',
    info: '',
    subscribe: false,
    firstOrder: false
  },
  3: {
    title: '3 Bottles',
    subtitle: 'Great For Just You!',
    price: '$112.48',
    billed: 'One Time',
    img: '/images/bottle-3-dark.png',
    href: 'https://ambrosia-nutraceuticals.myshopify.com/cart/7696563470389:3?discount=OS3BUNDLE',
    off: '• 25% Off Already Applied',
    info: '<p class="color-brand mb-0">Regular Price for (3) Bottles is $149.97</p> <span class="color-dark">You Save $37.49 Instantly </span><span class="color-dark"> on this bundle deal.</span>',
    subscribe: false,
    firstOrder: false
  },
  6: {
    title: '6 Bottles',
    subtitle: 'Great For A Family!',
    price: '$218.96',
    billed: 'One Time',
    img: '/images/bottle-6-dark.png',
    href: 'https://ambrosia-nutraceuticals.myshopify.com/cart/7696563470389:6?discount=OS6BUNDLE',
    off: '• 27% Off Already Applied',
    info: '<p class="color-brand">Regular Price for (6) Bottles is $299.94</p> <span class="color-dark"> You Save $80.98 Instantly </span> <span class="color-dark">on this bundle deal.</span>',
    subscribe: false,
    firstOrder: false
  },
  subscribe: {
    title: '1 Bottle',
    subtitle: 'Every 30 Days',
    price: '$39.99',
    billed: 'Every 30 Days',
    img: '/images/bottle-dark.png',
    href: 'https://ambrosia-nutraceuticals.myshopify.com/tools/checkout/buy_button/add_to_cart?p=2664023228501&v=22959730294869&g=122325&fn=1&ft=3&dp=$39.99&rdp=1&rup=3999',
    off: '',
    info: '',
    subscribe: true,
    firstOrder: false
  },
}


$('.js-close-order').click(function (e) {
  e.preventDefault()
  $('body').removeClass('order-show')
})
$('.js-show-order').click(function (e) {
  e.preventDefault()
  $('body').addClass('order-show')
  const { subscribe } = orderData
  if (subscribe.firstOrder) {
    orderData.subscribe.off = '+20% off'
  }

})


const orderPlan = $('.order__plan');
const orderDetail = $('.order__detail');
const orderList = $('.order__options-list');
const orderSlider = $('.order__options-slider');
const orderOptions = $('.order__options');
const orderCheckout = $('.order__options-checkout');
let timeout = setTimeout(() => { });
let initSlider = true;
let initial = true;

// function initialSlider() {
  orderSlider.slick({
    centerMode: true,
    slidesToShow: 3,
    focusOnSelect: true,
    draggable: false,
    autoPlay: false,
    arrows: false,
    responsive: [
      {
        breakpoint: 550,
        settings: {
          centerMode: true,
          variableWidth: true,
          centerPadding: '30px',
          slidesToShow: 1,
          focusOnSelect: false,
          draggable: false,
          autoPlay: false,
          infinite: false,

    arrows: false,
        }
      },
    ]
  })
// }


$('.js-select-order').on('click', function (e) {
  e.preventDefault();

  // if (initSlider) {

  //   $('.order__detail').fadeIn();
  //   initialSlider();
  //   initSlider = false;
  // }
  const options = $(this).data('options');
  clearTimeout(timeout)
  if ($(this).hasClass('plan-card')) {
    $('.plan-card').removeClass('is-active');
    $(this).addClass('is-active');
    $('.order__detail').addClass('is-show')
  }
  if (initial) {


    if (options === 'order') {
      showSelectOption(orderCheckout)
      const orderQty = $(this).data('qty');
      setOrderData(orderData[orderQty])
      timeout = setTimeout(() => {

        scaleOut(orderPlan);
        scaleIn(orderDetail);
        scaleIn(orderCheckout);
        $('.order').scrollTo(orderDetail, 1500, { offset: 0,easing: 'linear'   }).dequeue('fx');

        initial = false
      }, 2000)
      return
    }
    scaleOut(orderPlan);
    scaleIn(orderDetail);

  }

  if (options === 'one-time') {
    showSelectOption(orderSlider)
    scaleIn(orderCheckout);
    $('.order').scrollTo(orderDetail, 1500, { offset: 0, easing: 'linear'   }).dequeue('fx');
  }

  if (options === 'order') {
    showSelectOption(orderCheckout)
    const orderQty = $(this).data('qty');
    setOrderData(orderData[orderQty])
    $('.order').scrollTo(orderDetail, 1500, { offset: 0, easing: 'linear' }).dequeue('fx');
    return
  }
})

$('.js-checkout-cancel').click(function () {
  scaleReset(orderPlan);
  scaleReset(orderDetail);
  scaleReset(orderCheckout);
  showSelectOption(orderList)
  $('.plan-card').removeClass('is-active');

  initial = true
})

function showSelectOption(selectScreen) {
  orderOptions.removeClass('is-shown')
  selectScreen.addClass('is-shown')
}

function setOrderData({ title, subtitle, off, info, price, billed, src, href, subscribe, firstOrder }) {

  $('.js-checkout-title').text(title)
  $('.js-checkout-subtitle').text(subtitle)
  $('.js-checkout-info').html(info)
  $('.js-checkout-off').text(off)
  $('.js-checkout-price').text(price)
  $('.js-checkout-billed').text(billed)
  $('.js-checkout-img').attr('src', src)
  $('.js-checkout-link').attr('href', href);
  subscribe ? $('.js-checkout-icon').show() : $('.js-checkout-icon').hide()
  firstOrder ? $('.js-checkout-disclaimer').show() : $('.js-checkout-disclaimer').hide()

}

function scaleIn(item) {
  item.addClass('scale-in')
}
function scaleOut(item) {
  item.addClass('scale-out')
}
function scaleReset(item) {
  item.removeClass('scale-out');
  item.removeClass('scale-in');
}